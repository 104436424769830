/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyB2D8A6aeAFzBwRmPRkhzogl9bsuUHiCSk",
  "appId": "1:968648435356:web:a30f4e742158e042ed2f59",
  "authDomain": "schooldog-i-evans-ga.firebaseapp.com",
  "measurementId": "G-NFCKRHTHSX",
  "messagingSenderId": "968648435356",
  "project": "schooldog-i-evans-ga",
  "projectId": "schooldog-i-evans-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-evans-ga.appspot.com"
}
